// src/pages/ResetPassword.js
import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import api from '../api'; // Your Axios instance
import { useSearchParams } from 'react-router-dom';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState('info');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match.');
            setVariant('danger');
            return;
        }

        try {
            const response = await api.post('/reset-password', null, {
                params: {
                    token: token,
                    newPassword: newPassword
                }
            });
            setMessage(response.data);
            setVariant('success');
        } catch (error) {
            if (error.response && error.response.data) {
                setMessage(error.response.data);
                setVariant('danger');
            } else {
                setMessage('An error occurred.');
                setVariant('danger');
            }
        }
    };

    return (
        <Container style={{ maxWidth: '400px', marginTop: '50px' }}>
            <h1 className="text-center mb-4">Reset Your Password</h1>
            { !token ? (
                <Alert variant="danger">Invalid or missing token.</Alert>
            ) : (
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="newPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="confirmPassword">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button type="submit" variant="primary" className="w-100">
                        Reset Password
                    </Button>
                </Form>
            )}
            {message && (
                <Alert variant={variant} className="mt-3 text-center">
                    {message}
                </Alert>
            )}
        </Container>
    );
};

export default ResetPassword;