// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Profile from './pages/Profile';
import AccessoryPage from './pages/AccessoryPage';
import AudioEquipmentPage from './pages/AudioEquipmentPage';
import CameraPage from './pages/CameraPage';
import LensPage from './pages/LensPage';
import LightingPage from './pages/LightingPage';
import ProjectorPage from './pages/ProjectorPage';
import TripodPage from './pages/TripodPage';
import UsedItemPage from './pages/UsedItemPage';
import Footer from './components/common/Footer';
import PrivateRoute from './components/common/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import Header from './components/common/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container} from 'react-bootstrap';
import AllItemsPage from './pages/AllItemsPage';
import ItemDetailPage from './pages/ItemDetailPage';
import AdminItemsPage from './pages/AdminItemsPage';
import AdminPricingPage from './pages/AdminPricingPage';
import 'react-datepicker/dist/react-datepicker.css';
import Unauthorized from './pages/Unauthorized';
import AdminHomePage from './pages/AdminHomePage';
import StoreHoursPage from './pages/StoreHoursPage';
import HolidaysPage from './pages/HolidaysPage';
import AdminLayout from './components/AdminLayout';
import AdminItemDetailsPage from './pages/AdminItemDetailsPage';
import AdminRentalPolicyPage from "./pages/AdminRentalPolicyPage";
import AdminBookingsCalendar from './pages/AdminBookingsCalendar';
import BookingDetailsModal from "./components/BookingDetailsModal";
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import CartPage from "./pages/CartPage";
import ConfirmationPage from "./pages/ConfirmationPage";
import AdminUsersPage from './pages/AdminUsersPage';


const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Header />
                <Container>
                    <Routes>
                        {/* Public Routes */}
                        <Route path="/" element={<Home />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/all-items" element={<AllItemsPage />} />
                        <Route path="/items/:itemId" element={<ItemDetailPage />} />
                        <Route path="/unauthorized" element={<Unauthorized />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />

                        {/* Private Routes */}
                        <Route
                            path="/profile"
                            element={
                                <PrivateRoute>
                                    <Profile />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/cart"
                            element={
                                <PrivateRoute>
                                    <CartPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/confirmation"
                            element={
                                <PrivateRoute>
                                    <ConfirmationPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/accessories"
                            element={
                                <PrivateRoute>
                                    <AccessoryPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/audioequipments"
                            element={
                                <PrivateRoute>
                                    <AudioEquipmentPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/cameras"
                            element={
                                <PrivateRoute>
                                    <CameraPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/lenses"
                            element={
                                <PrivateRoute>
                                    <LensPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/lightings"
                            element={
                                <PrivateRoute>
                                    <LightingPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projectors"
                            element={
                                <PrivateRoute>
                                    <ProjectorPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/tripods"
                            element={
                                <PrivateRoute>
                                    <TripodPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/useditems"
                            element={
                                <PrivateRoute>
                                    <UsedItemPage />
                                </PrivateRoute>
                            }
                        />

                        {/* Admin Routes */}
                            <Route
                            path="/admin/*"
                            element={
                            <PrivateRoute requiredRole="ROLE_ADMIN">
                            <AdminLayout />
                            </PrivateRoute>
                        }
                    >
                        <Route index element={<AdminHomePage />} />
                        <Route path="store-hours" element={<StoreHoursPage />} />
                        <Route path="holidays" element={<HolidaysPage />} />
                        <Route path="items" element={<AdminItemsPage />} />
                        <Route path="items/:itemId/pricing" element={<AdminPricingPage />} />
                        <Route path="items/:itemId/details" element={<AdminItemDetailsPage />} />
                        <Route path="rental-policy" element={<AdminRentalPolicyPage />} />
                        <Route path="bookings-calendar" element={<AdminBookingsCalendar />} />
                        <Route path="modal-overlay" element={<BookingDetailsModal />} />
                        <Route path="manage-users" element={<AdminUsersPage />} />
                    </Route>

                    {/* Fallback Route */}
                    <Route path="*" element={<Unauthorized />} />
                </Routes>
            </Container>
            <Footer className="bg-dark text-white text-center py-3">
                © 2024 Rent Ranger
            </Footer>
        </Router>
</AuthProvider>
);
};

export default App;
