import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import api from "../api";

const Profile = () => {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                console.log('Fetching user profile...');
                const response = await api.get(`/profile`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                console.log('User profile fetched:', response.data);
                setProfile(response.data);
            } catch (err) {
                console.error('Failed to fetch profile:', err);
                setError('Failed to fetch profile');
            }
        };

        fetchProfile();
    }, []);

    if (error) {
        return <div>{error}</div>;
    }

    if (!profile) {
        return <div>Loading...</div>;
    }

    return (
        <Container className="mt-5">
            <Row>
                <Col>
                    <h1>Profile</h1>
                    <p>
                        <strong>First Name:</strong> {profile.firstName}
                    </p>
                    <p>
                        <strong>Last Name:</strong> {profile.lastName}
                    </p>
                    <p>
                        <strong>Username:</strong> {profile.username}
                    </p>
                    <p>
                        <strong>Email:</strong> {profile.email}
                    </p>
                    <p>
                        <strong>Phone:</strong> {profile.phone}
                    </p>
                    <p>
                        <strong>Customer ID:</strong> {profile.customerId}
                    </p>
                    {/* Add more fields if necessary */}
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;
