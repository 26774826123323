// src/pages/AdminRentalPolicyPage.js
import React, { useEffect, useState } from 'react';
import api from '../api';

const AdminRentalPolicyPage = () => {
    const [rentalPolicy, setRentalPolicy] = useState({ discountClosedDaysAndHolidays: true });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRentalPolicy = async () => {
            try {
                const response = await api.get('/store/rental-policy');
                setRentalPolicy(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch rental policy:', error);
                setLoading(false);
            }
        };
        fetchRentalPolicy();
    }, []);

    const handleToggle = async () => {
        try {
            const updatedPolicy = {
                ...rentalPolicy,
                discountClosedDaysAndHolidays: !rentalPolicy.discountClosedDaysAndHolidays,
            };
            const response = await api.put('/store/rental-policy', updatedPolicy);
            setRentalPolicy(response.data);
            alert('Rental policy updated successfully!');
        } catch (error) {
            console.error('Failed to update rental policy:', error);
            alert('Failed to update rental policy.');
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div>
            <h1>Admin Rental Policy</h1>
            <label>
                <input
                    type="checkbox"
                    checked={rentalPolicy.discountClosedDaysAndHolidays}
                    onChange={handleToggle}
                />
                Discount Closed Days and Holidays
            </label>
        </div>
    );
};

export default AdminRentalPolicyPage;