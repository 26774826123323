// src/pages/AdminHomePage.js
import React from 'react';
import { Container } from 'react-bootstrap';
import './AdminHomePage.css';

const AdminHomePage = () => {
    return (
        <Container className="admin-home-container">
            <h1>Welcome to the Admin Dashboard</h1>
            <p>Use the navigation above to manage the system.</p>
        </Container>
    );
};

export default AdminHomePage;
