// src/pages/ForgotPassword.js
import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import api from '../api'; // Your Axios instance

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState('info');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/forgot-password?email=' + encodeURIComponent(email));
            setMessage(response.data);
            setVariant('success');
        } catch (error) {
            if (error.response && error.response.data) {
                setMessage(error.response.data);
                setVariant('danger');
            } else {
                setMessage('An error occurred.');
                setVariant('danger');
            }
        }
    };

    return (
        <Container style={{ maxWidth: '400px', marginTop: '50px' }}>
            <h1 className="text-center mb-4">Forgot Password</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Form.Group>
                <Button type="submit" variant="primary" className="w-100">
                    Send Reset Link
                </Button>
            </Form>
            {message && (
                <Alert variant={variant} className="mt-3 text-center">
                    {message}
                </Alert>
            )}
        </Container>
    );
};

export default ForgotPassword;