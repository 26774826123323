// src/components/common/Header.js
import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { Navbar, Nav, Container, Button, NavDropdown } from 'react-bootstrap';
import './Header.css';
import logo from '../../logo.svg';

const Header = () => {
    const { user, logout } = useContext(AuthContext);

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand as={NavLink} to="/"><img src={logo} alt="Logo" width="40" height="40" className="me-2" />Rent
                    Ranger</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={NavLink} to="/">Home</Nav.Link>
                        {user ? (
                            <>
                                <Nav.Link as={NavLink} to="/profile">Profile</Nav.Link>
                                <NavDropdown title="Cameras" id="camera-brand-dropdown">
                                    <NavDropdown.Item as={Link} to="/cameras?brand=Canon">Canon</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/cameras?brand=Sony">Sony</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/cameras?brand=Nikon">Nikon</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Lenses" id="lens-brand-dropdown">
                                    <NavDropdown.Item as={Link} to="/lenses?brand=Canon">Canon</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/lenses?brand=Sony">Sony</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/lenses?brand=Nikon">Nikon</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link as={NavLink} to="/lightings">Lighting</Nav.Link>
                                <Nav.Link as={NavLink} to="/accessories">Accessories</Nav.Link>
                                <Nav.Link as={NavLink} to="/projectors">Projectors</Nav.Link>
                                <Nav.Link as={NavLink} to="/all-items">All Rental Items</Nav.Link>
                                <Nav.Link as={NavLink} to="/cart">Cart</Nav.Link>

                                {/* Admin Links */}
                                {user.roles.includes('ROLE_ADMIN') && (
                                    <NavDropdown title="Admin" id="admin-nav-dropdown">
                                        <NavDropdown.Item as={Link} to="/admin">
                                            Dashboard
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/items">
                                            Manage Items
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/store-hours">
                                            Store Hours
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/holidays">
                                            Holidays
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/rental-policy">
                                            Rental Policies
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/bookings-calendar">
                                            Bookings Calendar
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/manage-users">
                                            Manage Users
                                        </NavDropdown.Item>
                                        {/* Add more admin links if needed */}
                                    </NavDropdown>
                                )}

                                <Button variant="outline-light" onClick={logout}>Logout</Button>
                            </>
                        ) : (
                            <>
                                <Nav.Link as={NavLink} to="/login">Login</Nav.Link>
                                <Nav.Link as={NavLink} to="/register">Register</Nav.Link>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;