// src/pages/AdminUsersPage.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import './AdminUsersPage.css';

const AdminUsersPage = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchUsers = async () => {
        try {
            const response = await api.get('/users');
            setUsers(response.data);
        } catch (err) {
            console.error(err);
            setError('Failed to load users');
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const toggleAdmin = async (userId) => {
        try {
            await api.put(`/users/${userId}/toggle-admin`);
            // Reload user list to reflect changes
            await fetchUsers();
        } catch (err) {
            console.error(err);
            alert('Failed to update admin status');
        }
    };

    if (error) return <p>{error}</p>;

    // Filter users by searchTerm (username or email)
    const filteredUsers = users.filter(user => {
        const searchLower = searchTerm.toLowerCase();
        return (
            user.username.toLowerCase().includes(searchLower) ||
            user.email.toLowerCase().includes(searchLower)
        );
    });

    return (
        <div className="admin-users-container">
            <h2>Manage Users</h2>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by username or email..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>
            <table className="admin-users-table">
                <thead>
                <tr>
                    <th>Customer ID</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Admin?</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {filteredUsers.map(user => {
                    const isAdmin = user.roles.some(role => role.name === 'ROLE_ADMIN');
                    return (
                        <tr key={user.id}>
                            <td>{user.customerId}</td>
                            <td>{user.firstName} {user.lastName}</td>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td>{isAdmin ? 'Yes' : 'No'}</td>
                            <td>
                                <button
                                    onClick={() => toggleAdmin(user.id)}
                                    className={isAdmin ? 'remove-admin-btn' : 'make-admin-btn'}
                                >
                                    {isAdmin ? 'Remove Admin' : 'Make Admin'}
                                </button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};

export default AdminUsersPage;