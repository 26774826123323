// src/pages/StoreHoursPage.js
import React, { useState, useEffect } from 'react';
import api from '../api';
import './StoreHoursPage.css';

const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

const StoreHoursPage = () => {
    const [storeHours, setStoreHours] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchStoreHours = async () => {
            try {
                const response = await api.get('/store/hours');
                setStoreHours(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch store hours.');
                setLoading(false);
                console.error(err);
            }
        };

        fetchStoreHours();
    }, []);

    const handleInputChange = (index, field, value) => {
        const updatedHours = [...storeHours];
        updatedHours[index][field] = value;
        setStoreHours(updatedHours);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            await api.put('/store/hours', storeHours);
            alert('Store hours updated successfully!');
        } catch (err) {
            alert('Failed to update store hours.');
            console.error(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="store-hours-container">
            <h1>Manage Store Hours</h1>
            <table className="store-hours-table">
                <thead>
                <tr>
                    <th>Day</th>
                    <th>Open</th>
                    <th>Opening Time</th>
                    <th>Closing Time</th>
                </tr>
                </thead>
                <tbody>
                {storeHours.map((sh, index) => (
                    <tr key={sh.dayOfWeek}>
                        <td>{sh.dayOfWeek}</td>
                        <td>
                            <input
                                type="checkbox"
                                checked={sh.open}
                                onChange={(e) =>
                                    handleInputChange(index, 'open', e.target.checked)
                                }
                            />
                        </td>
                        <td>
                            <input
                                type="time"
                                value={sh.openingTime || ''}
                                onChange={(e) =>
                                    handleInputChange(index, 'openingTime', e.target.value)
                                }
                                disabled={!sh.open}
                            />
                        </td>
                        <td>
                            <input
                                type="time"
                                value={sh.closingTime || ''}
                                onChange={(e) =>
                                    handleInputChange(index, 'closingTime', e.target.value)
                                }
                                disabled={!sh.open}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <button onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting ? 'Updating...' : 'Update Store Hours'}
            </button>
        </div>
    );
};

export default StoreHoursPage;