// src/pages/AdminItemDetailsPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import api from '../api';
import './AdminItemDetailsPage.css';
import 'react-quill/dist/quill.snow.css'; // Import React Quill styles

const AdminItemDetailsPage = () => {
    const { itemId } = useParams();
    const [item, setItem] = useState(null);
    const [pricingTiers, setPricingTiers] = useState([]);
    const [note, setNote] = useState('');
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        const fetchItemAndPricing = async () => {
            try {
                const itemResponse = await api.get(`/items/${itemId}`);
                setItem(itemResponse.data);
                setNote(itemResponse.data.note || '');

                const pricingResponse = await api.get(`/pricing/item/${itemId}`);
                setPricingTiers(pricingResponse.data);
            } catch (error) {
                console.error('Failed to fetch item or pricing tiers:', error);
            }
        };
        fetchItemAndPricing();
    }, [itemId]);

    const handleSave = async () => {
        setSaving(true);
        try {
            await api.put(`/items/${itemId}`, {
                ...item,
                note, // Update the note field
                detailsEdited: true, // Set the flag
            });
            alert('Details updated successfully!');
        } catch (error) {
            console.error('Failed to update item details:', error);
            alert('Failed to update details.');
        } finally {
            setSaving(false);
        }
    };

    const handleResetDetailsEdited = async () => {
        try {
            await api.put(`/items/admin/${itemId}/reset-details-edited`);
            alert('Details have been reset to Lightspeed. Future synchronizations will update the details from Lightspeed.');
        } catch (error) {
            console.error('Failed to reset details edited flag:', error);
            alert('Failed to reset details.');
        }
    };


    if (!item) return <p>Loading...</p>;

    return (
        <div className="admin-item-details-container">
            <h1>Manage Details for {item.description}</h1>

            <div className="admin-item-details-content">
                {/* Item Image */}
                <div className="item-image">
                    {item.imageUrl ? (
                        <img src={item.imageUrl} alt={item.description} />
                    ) : (
                        <div className="item-placeholder">No Image Available</div>
                    )}
                </div>

                {/* Pricing Tiers */}
                <div className="pricing-tiers">
                    <h2>Pricing Tiers</h2>
                    <table className="pricing-table">
                        <thead>
                        <tr>
                            <th>Min Nights</th>
                            <th>Max Nights</th>
                            <th>Price Per Night</th>
                        </tr>
                        </thead>
                        <tbody>
                        {pricingTiers.map((tier) => (
                            <tr key={tier.id}>
                                <td>{tier.minNights}</td>
                                <td>{tier.maxNights || 'No Max'}</td>
                                <td>${tier.pricePerNight.toFixed(2)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                {/* Details Editor */}
                <div className="details-editor">
                    <h2>Edit Details</h2>
                    <ReactQuill value={note} onChange={setNote} />
                    <button onClick={handleSave} disabled={saving}>
                        {saving ? 'Saving...' : 'Save Details'}
                    </button>
                    {/* Add the reset button */}
                    <button onClick={handleResetDetailsEdited} disabled={saving}>
                        Reset Details to Lightspeed
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdminItemDetailsPage;