// src/pages/HolidaysPage.js
import React, { useState, useEffect } from 'react';
import api from '../api';
import './HolidaysPage.css';

const HolidaysPage = () => {
    const [holidays, setHolidays] = useState([]);
    const [newHolidayName, setNewHolidayName] = useState('');
    const [newHolidayDate, setNewHolidayDate] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchHolidays = async () => {
            try {
                const response = await api.get('/store/holidays');
                setHolidays(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch holidays.');
                setLoading(false);
                console.error(err);
            }
        };

        fetchHolidays();
    }, []);

    const handleAddHoliday = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const newHoliday = { name: newHolidayName, date: newHolidayDate };
            const response = await api.post('/store/holidays', newHoliday);
            setHolidays([...holidays, response.data]);
            setNewHolidayName('');
            setNewHolidayDate('');
            alert('Holiday added successfully!');
        } catch (err) {
            alert('Failed to add holiday.');
            console.error(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDeleteHoliday = async (id) => {
        if (!window.confirm('Are you sure you want to delete this holiday?')) return;
        try {
            await api.delete(`/store/holidays/${id}`);
            setHolidays(holidays.filter((holiday) => holiday.id !== id));
            alert('Holiday deleted successfully!');
        } catch (err) {
            alert('Failed to delete holiday.');
            console.error(err);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="holidays-container">
            <h1>Manage Holidays</h1>
            <table className="holidays-table">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Holiday Name</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {holidays.map((holiday) => (
                    <tr key={holiday.id}>
                        <td>{holiday.date}</td>
                        <td>{holiday.name}</td>
                        <td>
                            <button
                                className="delete-button"
                                onClick={() => handleDeleteHoliday(holiday.id)}
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <h2>Add New Holiday</h2>
            <form className="add-holiday-form" onSubmit={handleAddHoliday}>
                <div className="form-group">
                    <label>Date:</label>
                    <input
                        type="date"
                        value={newHolidayDate}
                        onChange={(e) => setNewHolidayDate(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Holiday Name:</label>
                    <input
                        type="text"
                        value={newHolidayName}
                        onChange={(e) => setNewHolidayName(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Adding...' : 'Add Holiday'}
                </button>
            </form>
        </div>
    );
};

export default HolidaysPage;