import React from 'react';
import './BookingDetailsModal.css';
import api from '../api';

const BookingDetailsModal = ({ date, bookings, onClose, onUpdate }) => {
    const handleDelete = async (bookingId) => {
        if (window.confirm('Are you sure you want to delete this booking?')) {
            try {
                await api.delete(`/admin/bookings/${bookingId}`);
                alert('Booking deleted successfully.');
                onUpdate();
            } catch (error) {
                console.error('Failed to delete booking:', error);
                alert('Failed to delete booking.');
            }
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Bookings on {new Date(date).toLocaleDateString()}</h2>
                <button className="close-button" onClick={onClose}>
                    Close
                </button>
                <table className="bookings-table">
                    <thead>
                    <tr>
                        <th>Customer Name</th>
                        <th>Rental Dates</th>
                        <th>Item Id</th>
                        <th>Customer Email</th>
                        <th>Number of Rental Nights</th>
                        <th>Total Price</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {bookings.map((booking) => (
                        <tr key={booking.id}>
                            <td>{booking.customerName}</td>
                            <td>
                                {booking.startDate} to {booking.endDate}
                            </td>
                            <td>{booking.item.itemId}</td>
                            <td>{booking.customerEmail}</td>
                            <td>{booking.rentalDays}</td>
                            <td>${booking.totalPrice.toFixed(2)}</td>
                            <td>
                                <button onClick={() => handleDelete(booking.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BookingDetailsModal;