// src/pages/CartPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import './CartPage.css';

const CartPage = () => {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            const response = await api.get('/profile');
            setUserProfile(response.data);
        };
        fetchUserProfile();
    }, []);

    useEffect(() => {
        const fetchCartItems = async () => {
            try {
                const response = await api.get('/cart');
                setCartItems(response.data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError('Failed to load cart items');
                setLoading(false);
            }
        };

        fetchCartItems();
    }, []);

    if (loading) return <p>Loading cart...</p>;
    if (!userProfile) return <p>Loading your profile...</p>;
    if (error) return <p>{error}</p>;

    const handleRemove = async (id) => {
        try {
            await api.delete(`/cart/${id}`);
            setCartItems(cartItems.filter(ci => ci.id !== id));
        } catch (err) {
            console.error('Failed to remove item:', err);
        }
    };

    const handleConfirmBooking = async () => {
        if (cartItems.length === 0) {
            alert("Your cart is empty!");
            return;
        }

        try {
            // 1. Create layaway lines on Lightspeed
            // Send all cart items to a new endpoint that will loop through and create them.
            const lightspeedPayload = cartItems.map(ci => ({
                employeeID: ci.employeeID,
                shopID: ci.shopID,
                itemID: ci.itemID,
                unitPrice: ci.unitPrice.toFixed(2),
                isLayaway: "true",
                customerID: ci.customerId,
                unitQuantity: ci.unitQuantity.toString(),
                //to put in SaleNote
                startDate: ci.startDate,
                endDate: ci.endDate,
            }));

            const lightspeedResponse = await api.post('/lightspeed/layaway', { items: lightspeedPayload });

            // Check if Lightspeed creation succeeded
            if (!lightspeedResponse.data.success) {
                alert("Failed to create layaway lines in Lightspeed. Please try again.");
                return;
            }

            // 2. Create rental bookings for each cart item
            for (const ci of cartItems) {
                const booking = {
                    startDate: ci.startDate,
                    endDate: ci.endDate,
                    item: { itemId: ci.itemID },
                    customerName: userProfile.firstName + " " + userProfile.lastName,
                    customerEmail: userProfile.email,
                    totalPrice: ci.unitPrice,
                    rentalDays: ci.unitQuantity
                };
                await api.post('/rentals/book', booking);
            }

            // 3. Clear cart items
            for (const ci of cartItems) {
                await api.delete(`/cart/${ci.id}`);
            }

            // 4. Redirect to confirmation page
            navigate('/confirmation');

        } catch (err) {
            console.error("Error during confirmation process:", err);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <div className="cart-container">
            <h1>Your Cart</h1>
            {cartItems.length === 0 ? (
                <p>Your cart is empty</p>
            ) : (
                <div>
                    <div className="cart-header-row">
                        <div className="cart-header-image">Image</div>
                        <div className="cart-header-description">Item</div>
                        <div className="cart-header-dates">Start Date</div>
                        <div className="cart-header-dates">End Date</div>
                        <div className="cart-header-unitprice">Total Price</div>
                        <div className="cart-header-quantity">Qty</div>
                        <div className="cart-header-total">Price/Day</div>
                        <div className="cart-header-action">Action</div>
                    </div>

                    <div className="cart-items">
                        {cartItems.map((cartItem) => {
                            const totalPerDay = parseFloat(cartItem.unitPrice) / parseInt(cartItem.unitQuantity, 10);
                            return (
                                <div className="cart-item-row" key={cartItem.id}>
                                    <img
                                        src={cartItem.imageUrl || '/placeholder-image.jpg'}
                                        alt={cartItem.itemDescription || 'Item'}
                                        className="cart-item-image"
                                    />
                                    <div className="cart-item-description">
                                        {cartItem.itemDescription || 'No Description Available'}
                                    </div>
                                    <div className="cart-item-dates">{cartItem.startDate}</div>
                                    <div className="cart-item-dates">{cartItem.endDate}</div>
                                    <div className="cart-item-unit-price">
                                        ${parseFloat(cartItem.unitPrice).toFixed(2)}
                                    </div>
                                    <div className="cart-item-quantity">
                                        {cartItem.unitQuantity}
                                    </div>
                                    <div className="cart-item-total">
                                        ${totalPerDay.toFixed(2)}
                                    </div>
                                    <button
                                        className="cart-item-remove"
                                        onClick={() => handleRemove(cartItem.id)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            );
                        })}
                    </div>

                    <button className="confirm-booking-button" onClick={handleConfirmBooking}>Confirm Booking</button>
                </div>
            )}
            <p>Finalize your rental at the store. Payment will be taken upon pickup.</p>
        </div>
    );
};

export default CartPage;
