import React, { useEffect, useState } from 'react';
import api from '../api';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import BookingDetailsModal from '../components/BookingDetailsModal';
import './AdminBookingsCalendar.css';

const AdminBookingsCalendar = () => {
    const [bookings, setBookings] = useState([]);
    const [events, setEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedBookings, setSelectedBookings] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await api.get('/admin/bookings');
                setBookings(response.data);

                // Convert bookings to events for the calendar
                const eventsData = response.data.map((booking) => ({
                    id: booking.id,
                    title: booking.item.description,
                    start: booking.startDate,
                    end: booking.endDate,
                    allDay: true,
                    extendedProps: {
                        customerName: booking.customerName,
                        customerEmail: booking.customerEmail,
                        rentalDays: booking.rentalDays,
                        totalPrice: booking.totalPrice,
                        itemId: booking.item.itemId,
                    },
                }));
                setEvents(eventsData);
            } catch (error) {
                console.error('Failed to fetch bookings:', error);
            }
        };
        fetchBookings();
    }, []);

    const handleDateClick = (arg) => {
        const date = arg.dateStr;
        // Filter bookings that include the selected date
        const bookingsOnDate = bookings.filter((booking) => {
            return date >= booking.startDate && date <= booking.endDate;
        });

        if (bookingsOnDate.length > 0) {
            setSelectedDate(date);
            setSelectedBookings(bookingsOnDate);
            setModalOpen(true);
        }
    };

    const handleEventClick = (info) => {
        const event = info.event;
        const bookingId = event.id;

        // Find the booking associated with this event
        const booking = bookings.find((b) => b.id === parseInt(bookingId));

        if (booking) {
            setSelectedDate(event.startStr);
            setSelectedBookings([booking]);
            setModalOpen(true);
        }
    };

    return (
        <div className="admin-bookings-calendar">
            <h1>Rental Bookings Calendar</h1>
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={events}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                selectable={false}
                editable={false}
            />

            {modalOpen && (
                <BookingDetailsModal
                    date={selectedDate}
                    bookings={selectedBookings}
                    onClose={() => setModalOpen(false)}
                    onUpdate={() => {
                        // Refresh bookings after deletion
                        setModalOpen(false);
                        window.location.reload();
                    }}
                />
            )}
        </div>
    );
};

export default AdminBookingsCalendar;
