// src/pages/ConfirmationPage.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import './ConfirmationPage.css';

const ConfirmationPage = () => {
    const [storeInfo, setStoreInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStoreInfo = async () => {
            try {
                const response = await api.get('/store/info');
                setStoreInfo(response.data);
            } catch (error) {
                console.error('Failed to fetch store info:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchStoreInfo();
    }, []);

    if (loading) return <p>Loading confirmation...</p>;

    if (!storeInfo) return <p>Could not load store information.</p>;

    return (
        <div className="confirmation-container">
            <h1>Booking Confirmed!</h1>
            <p>Your rental booking has been confirmed. We look forward to seeing you!</p>
            <h2>Store Location</h2>
            <p>{storeInfo.location}</p>
            <h2>Pickup Instructions</h2>
            <p>{storeInfo.instructions}</p>
            <h3>Your Next Steps</h3>
            <ul>
                <li>Check your email for a copy of your booking details.</li>
                <li>Bring a valid ID and form of payment.</li>
            </ul>
        </div>
    );
};

export default ConfirmationPage;
