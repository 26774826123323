// src/components/ItemCard.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './ItemCard.css';

const ItemCard = ({ item }) => {
    console.log("Rendering item:", item); // Debugging log

    return (
        <Link to={`/items/${item.itemId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="item-card">
                {item.imageUrl ? (
                    <img src={item.imageUrl} alt={item.description} className="item-card-image" />
                ) : (
                    <div className="item-card-placeholder">No Image Available</div>
                )}
                <div className="item-card-content">
                    <h3 className="item-card-title">{item.description}</h3>
                    {item.customSku && (
                        <p className="item-card-sku">{item.customSku}</p> // Add this line
                    )}
                    {/* */}
                </div>
            </div>
        </Link>
    );
};

export default ItemCard;