import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../api';
import './AdminItemsPage.css';
import { format } from 'date-fns';

const AdminItemsPage = () => {
    const [items, setItems] = useState([]);
    const [sortOption, setSortOption] = useState('description');
    const [filterCategory, setFilterCategory] = useState('All');
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchAllItems = async () => {
            const response = await api.get('/items/all');
            setItems(response.data);
            // Extract unique categories
            const uniqueCategories = Array.from(new Set(response.data.map(item => item.category).filter(Boolean)));
            setCategories(uniqueCategories);
        };
        fetchAllItems();
    }, []);

    const sortedItems = [...items].sort((a, b) => {
        if (sortOption === 'lastUpdated') {
            const dateA = a.lastUpdated ? new Date(a.lastUpdated) : new Date(0);
            const dateB = b.lastUpdated ? new Date(b.lastUpdated) : new Date(0);
            return dateB - dateA; // Newest first
        } else {
            return a.description.localeCompare(b.description);
        }
    });

    const filteredItems = sortedItems.filter((item) => {
        if (filterCategory === 'All') return true;
        return item.category === filterCategory;
    });

    return (
        <div className="admin-items-container">
            <h1>Admin - Items</h1>
            <div className="admin-items-controls">
                <label>
                    Sort by:
                    <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
                        <option value="description">Description</option>
                        <option value="lastUpdated">Last Updated</option>
                    </select>
                </label>
                <label>
                    Filter by Category:
                    <select value={filterCategory} onChange={(e) => setFilterCategory(e.target.value)}>
                        <option value="All">All</option>
                        {categories.map((category) => (
                            <option key={category} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
            <ul className="admin-items-list">
                {filteredItems.map((item) => (
                    <li key={item.itemId}>
                        <div className="admin-item-info">
                            <span className="admin-item-description">{item.description}</span>
                            {item.lastUpdated && (
                                <span className="admin-item-timestamp">
                                    Last Updated: {format(new Date(item.lastUpdated), 'yyyy-MM-dd HH:mm')}
                                </span>
                            )}
                        </div>
                        <div className="admin-item-actions">
                            <Link to={`/admin/items/${item.itemId}/pricing`}>Manage Pricing</Link>
                            <Link to={`/admin/items/${item.itemId}/details`}>Manage Details</Link>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdminItemsPage;
